import { PEERTUBE_CONSTANTS_KEYS } from "../common/utils";

export const getPeertubeAccessToken = async (uniqueApplicationKey) => {
  try {
    let accessTokenData = localStorage.getItem(`${PEERTUBE_CONSTANTS_KEYS.ACCESS_TOKEN}:${uniqueApplicationKey}`);
    accessTokenData = JSON.parse(accessTokenData);

    const { token } = accessTokenData;
    return { token, isExpired: false };
  } catch (error) {
    console.log(`Error from getPeertubeAccessToken > ${error}`);
  }
}
