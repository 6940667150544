import { locations } from "@contentful/app-sdk";
import { useSDK } from "@contentful/react-apps-toolkit";
import { useEffect, useMemo } from "react";
import ConfigScreen from "./locations/ConfigScreen";
import Field from "./locations/Field";

const ComponentLocationSettings = {
  [locations.LOCATION_APP_CONFIG]: ConfigScreen,
  [locations.LOCATION_ENTRY_FIELD]: Field,
};

const App = () => {
  const sdk = useSDK();

  const Component = useMemo(() => {
    for (const [location, component] of Object.entries(
      ComponentLocationSettings
    )) {
      if (sdk.location.is(location)) {
        return component;
      }
    }
  }, [sdk.location]);

  useEffect(() => {
    sdk?.window?.startAutoResizer();
    return () => {
      sdk?.window?.stopAutoResizer();
    };
  }, [sdk]);

  return Component ? <Component /> : null;
};

export default App;
