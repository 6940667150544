export const PEERTUBE_ENDPOINTS = {
  GET_OAUTH_CLIENT_DETAILS: '/api/v1/oauth-clients/local',
  GET_USER_TOKEN: '/api/v1/users/token',
  UPLOAD_VIDEO: '/api/v1/videos/upload',
  GET_VIDEO_DETAILS: '/api/v1/videos',
}

export const PEERTUBE_GRANT_TYPE = {
  REFRESH_TOKEN: 'refresh_token',
  PASSWORD: 'password'
}

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const generateAppUniqueKey = ids => {
  return `${ids?.organization || 'ORGANIZATION_ID'}:${ids.space || 'SPACE_ID'}:${ids.environment || 'ENV'}`;
}

export const PEERTUBE_CONSTANTS_KEYS = {
  CLIENT_ID: 'PEERTUBE_CLIENT_ID',
  CLIENT_SECRET: 'PEERTUBE_CLIENT_SECRET',
  ACCESS_TOKEN: 'PEERTUBE_ACCESS_TOKEN',
  REFRESH_TOKEN: 'PEERTUBE_REFRESH_TOKEN'
}

export const PEERTUBE_ASSET_STATE = {
  PUBLISHED: 1,
}