import {
  Form,
  FormControl,
  Heading,
  Paragraph,
  TextInput
} from "@contentful/f36-components";
import { /* useCMA, */ useSDK } from "@contentful/react-apps-toolkit";
import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import AxiosClient, { handleApiError } from "../common/api";
// import cookies from "../common/cookies";
import {
  PEERTUBE_CONSTANTS_KEYS,
  PEERTUBE_ENDPOINTS,
  PEERTUBE_GRANT_TYPE,
  generateAppUniqueKey
} from "../common/utils";
import "./Config.css";

const { Label } = FormControl;

const ConfigScreen = () => {
  const sdk = useSDK();

  const [parameters, setParameters] = useState({
    peertubeUrl: "",
    peertubeUsername: "",
    peertubePassword: "",
    peertubeChannelId: "",
    peertubeAccessToken: "",
    peertubeRefreshToken: "",
    peertubeClientId: "",
    peertubeClientSecret: "",
  });
  const UNIQUE_APPLICATION_KEY = generateAppUniqueKey(sdk.ids);

  const [isPeertubeConfigChanged, setIsPeertubeConfigChanged] = useState(false);

  const onConfigure = useCallback(async () => {
    // This method will be called when a user clicks on "Install"
    // or "Save" in the configuration screen.
    // for more details see https://www.contentful.com/developers/docs/extensibility/ui-extensions/sdk-reference/#register-an-app-configuration-hook

    // Get current the state of EditorInterface and other entities
    // related to this app installation
    const currentState = await sdk.app.getCurrentState();

    // const allCookies = cookies.getAll();

    const { peertubeUrl } = parameters;
    let updatedParameters = parameters;
    if (isPeertubeConfigChanged) {
      try {
        setIsPeertubeConfigChanged(false);
        const apiClient = new AxiosClient(peertubeUrl);

        const oauthClientResponse = await apiClient.get(
          PEERTUBE_ENDPOINTS.GET_OAUTH_CLIENT_DETAILS
        );
        const { data } = oauthClientResponse;
        const { client_id: clientId, client_secret: clientSecret } = data;

        // COOKIES
        // const oauthCookiesOptions = {
        //   path: '/',
        //   domain: window?.location?.host,
        // }
        // cookies.set(`${PEERTUBE_CONSTANTS_KEYS.CLIENT_ID}:${UNIQUE_APPLICATION_KEY}`, clientId, oauthCookiesOptions);
        // cookies.set(`${PEERTUBE_CONSTANTS_KEYS.CLIENT_SECRET}:${UNIQUE_APPLICATION_KEY}`, clientSecret, oauthCookiesOptions);

        localStorage.setItem(`${PEERTUBE_CONSTANTS_KEYS.CLIENT_ID}:${UNIQUE_APPLICATION_KEY}`, clientId);
        localStorage.setItem(`${PEERTUBE_CONSTANTS_KEYS.CLIENT_SECRET}:${UNIQUE_APPLICATION_KEY}`, clientSecret);

        if (clientId && clientSecret) {
          const loginData = {
            grant_type: PEERTUBE_GRANT_TYPE.PASSWORD,
            client_id: clientId,
            client_secret: clientSecret,
            username: parameters.peertubeUsername,
            password: parameters.peertubePassword,
          };
          const loginConfig = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };
          const loginDataStringify = qs.stringify(loginData);
          const { data: loginResponse } = await apiClient.post(
            PEERTUBE_ENDPOINTS.GET_USER_TOKEN,
            loginDataStringify,
            loginConfig
          );
          if (loginResponse && loginResponse.access_token) {
            // const accessTokenCookieOptions = {
            //   path: '/',
            //   domain: window?.location?.host,
            //   maxAge: loginResponse.expires_in
            // };
            // cookies.set(`${PEERTUBE_CONSTANTS_KEYS.ACCESS_TOKEN}:${UNIQUE_APPLICATION_KEY}`, loginResponse.access_token, accessTokenCookieOptions);

            // const refreshTokenCookieOptions = {
            //   path: '/',
            //   domain: window?.location?.host,
            //   maxAge: loginResponse.refresh_token_expires_in
            // };
            // cookies.set(`${PEERTUBE_CONSTANTS_KEYS.REFRESH_TOKEN}:${UNIQUE_APPLICATION_KEY}`, loginResponse.refresh_token, refreshTokenCookieOptions);

            const accessTokenExpiryDate = new Date().getTime() + loginResponse.expires_in;
            const accessTokenData = {
              token: loginResponse.access_token, expiry: accessTokenExpiryDate
            }

            localStorage.setItem(`${PEERTUBE_CONSTANTS_KEYS.ACCESS_TOKEN}:${UNIQUE_APPLICATION_KEY}`, JSON.stringify(accessTokenData));

            const refreshTokenExpiryDate = new Date().getTime() + loginResponse.refresh_token_expires_in;
            const refreshTokenData = {
              token: loginResponse.refresh_token, expiry: refreshTokenExpiryDate
            }

            localStorage.setItem(`${PEERTUBE_CONSTANTS_KEYS.REFRESH_TOKEN}:${UNIQUE_APPLICATION_KEY}`, JSON.stringify(refreshTokenData));

            updatedParameters = {
              ...parameters,
              peertubeClientId: clientId,
              peertubeClientSecret: clientSecret,
            };
          }
        }
      } catch (error) {
        return handleApiError(error, sdk);
      }
    }

    return {
      // Parameters to be persisted as the app configuration.
      parameters: updatedParameters,
      // In case you don't want to submit any update to app
      // locations, you can just pass the currentState as is
      targetState: currentState,
    };
  }, [parameters, sdk]);

  useEffect(() => {
    // `onConfigure` allows to configure a callback to be
    // invoked when a user attempts to install the app or update
    // its configuration.
    sdk.app.onConfigure(() => onConfigure());
  }, [sdk, onConfigure]);

  useEffect(() => {
    (async () => {
      // Get current parameters of the app.
      // If the app is not installed yet, `parameters` will be `null`.
      const currentParameters = await sdk.app.getParameters();
      if (currentParameters) {
        setParameters(currentParameters);
      }
      // Once preparation has finished, call `setReady` to hide
      // the loading screen and present the app to a user.
      sdk.app.setReady();
    })();
  }, [sdk]);

  return (
    <React.Fragment>
      <div className="config-background">
        <div className="config-body">
          <Heading>About Cappital Video Platform Plugin</Heading>
          <Paragraph>
            This app connects to Cappital Video Platform and allows you to upload videos to
            your content in Contentful. After entering your API Credentials then
            choose which JSON fields in your content model you would like to
            configure for Cappital Video Platform. For those configured fields you'll get
            a video uploader in the Contentful UI.
          </Paragraph>
          <hr className="config-splitter" />
          <Form>
            <Heading>Cappital Video Platform Credentials</Heading>
            <FormControl id="cvp-url" isRequired>
              <Label>URL</Label>
              <TextInput
                name="cvp-url"
                value={parameters.peertubeUrl || ""}
                onChange={(e) => {
                  setParameters({
                    ...parameters,
                    peertubeUrl: e.target.value,
                  });
                  if (e?.target?.value) {
                    setIsPeertubeConfigChanged(true);
                  } else {
                    setIsPeertubeConfigChanged(false);
                  }
                }}
              />
            </FormControl>
            <FormControl id="cvp-username" isRequired>
              <Label>Username</Label>
              <TextInput
                name="cvp-username"
                value={parameters.peertubeUsername || ""}
                onChange={(e) => {
                  setParameters({
                    ...parameters,
                    peertubeUsername: e?.target?.value,
                  });
                  if (e?.target?.value) {
                    setIsPeertubeConfigChanged(true);
                  } else {
                    setIsPeertubeConfigChanged(false);
                  }
                }}
              />
            </FormControl>
            <FormControl id="cvp-password" isRequired>
              <Label>Password</Label>
              <TextInput
                name="cvp-password"
                value={parameters.peertubePassword || ""}
                onChange={(e) => {
                  setParameters({
                    ...parameters,
                    peertubePassword: e?.target?.value,
                  });
                  if (e?.target?.value) {
                    setIsPeertubeConfigChanged(true);
                  } else {
                    setIsPeertubeConfigChanged(false);
                  }
                }}
                type="password"
              />
            </FormControl>
            <FormControl id="cvp-channel-id" isRequired>
              <Label>Channel ID</Label>
              <TextInput
                name="cvp-channel-id"
                value={parameters.peertubeChannelId || ""}
                onChange={(e) => {
                  setParameters({
                    ...parameters,
                    peertubeChannelId: e?.target?.value,
                  });
                }}
              />
            </FormControl>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfigScreen;
