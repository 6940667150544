import axios from 'axios';
import qs from "qs";
import { PEERTUBE_CONSTANTS_KEYS, PEERTUBE_ENDPOINTS, PEERTUBE_GRANT_TYPE, generateAppUniqueKey, sleep } from './utils';

class AxiosClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
      // You can add more default configurations here
    });
  }

  async get(url, config = {}) {
    const response = await this.client.get(url, config);
    return response
  }

  async post(url, data = {}, config = {}) {
    const response = await this.client.post(url, data, config);
    return response
  }

  async put(url, data = {}, config = {}) {
    const response = await this.client.put(url, data, config);
    return response
  }

  async delete(url, config = {}) {
    const response = await this.client.delete(url, config);
    return response
  }

}

export const handleApiError = async (error, contentfulSDK) => {
  const { response: res, message, config: { url: requestUrl } } = error;
  if (!res.status) {
    contentfulSDK.notifier.error(`API Error > ${message}`);
    return false;
  }

  switch (true) {
    case res.status === 400:
      const { data } = res;
      const { error: errorMessage } = data;
      contentfulSDK.notifier.error(errorMessage);
      return false;

    case res.status === 401: {
      const { data } = res;
      const { code } = data;
      if (code === 'invalid_token' && requestUrl !== PEERTUBE_ENDPOINTS.GET_USER_TOKEN) {
        contentfulSDK.notifier.warning(
          "Updating access token in background, Please wait..."
        );
        const UNIQUE_APPLICATION_KEY = generateAppUniqueKey(contentfulSDK.ids);
        const { parameters: { installation: parameters } } = contentfulSDK;
        const { peertubeUrl, peertubeClientId, peertubeClientSecret } = parameters;
        const loginData = {
          grant_type: PEERTUBE_GRANT_TYPE.PASSWORD,
          client_id: peertubeClientId,
          client_secret: peertubeClientSecret,
          username: parameters.peertubeUsername,
          password: parameters.peertubePassword,
        };
        const loginConfig = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const apiClient = new AxiosClient(peertubeUrl);

        const loginDataStringify = qs.stringify(loginData);
        const { data: loginResponse } = await apiClient.post(
          PEERTUBE_ENDPOINTS.GET_USER_TOKEN,
          loginDataStringify,
          loginConfig
        );
        if (loginResponse && loginResponse.access_token) {
          const accessTokenExpiryDate = new Date().getTime() + loginResponse.expires_in;
          const accessTokenData = {
            token: loginResponse.access_token, expiry: accessTokenExpiryDate
          }

          localStorage.setItem(`${PEERTUBE_CONSTANTS_KEYS.ACCESS_TOKEN}:${UNIQUE_APPLICATION_KEY}`, JSON.stringify(accessTokenData));

          const refreshTokenExpiryDate = new Date().getTime() + loginResponse.refresh_token_expires_in;
          const refreshTokenData = {
            token: loginResponse.refresh_token, expiry: refreshTokenExpiryDate
          }

          localStorage.setItem(`${PEERTUBE_CONSTANTS_KEYS.REFRESH_TOKEN}:${UNIQUE_APPLICATION_KEY}`, JSON.stringify(refreshTokenData));
        }

        await sleep(1000);
        contentfulSDK.notifier.success(
          "Updated access token, You can retry now!"
        );
      } else {
        contentfulSDK.notifier.error(
          "Looks like something is wrong with the Peertube credentials in the config. Please verify once..."
        );
      }
      return false;
    }

    case res.status === 404:
      if (requestUrl.startsWith(PEERTUBE_ENDPOINTS.GET_VIDEO_DETAILS)) {
        return Promise.resolve({ assetNotFound: true });
      }
      contentfulSDK.notifier.error(
        "Invalid request API not found"
      );
      return false;

    case res.status === 429:
      contentfulSDK.notifier.error(
        "Peertube API rate limit exceeded. Try closing the browser and wait a few minutes."
      );
      return false;

    case !res.ok:
      contentfulSDK.notifier.error(`API Error. ${res.status} ${res.statusText}`);
      return false;

    default:
      return true;
  }
}

export default AxiosClient;
