import {
  Box,
  Button,
  CopyButton,
  Flex,
  Form,
  FormControl,
  Note,
  Select,
  Spinner,
  Tabs,
  TextInput,
} from "@contentful/f36-components";
import { /* useCMA, */ useSDK } from "@contentful/react-apps-toolkit";
import { get } from "lodash";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import AxiosClient, { handleApiError } from "../common/api";
import {
  PEERTUBE_ASSET_STATE,
  PEERTUBE_ENDPOINTS,
  generateAppUniqueKey,
  sleep,
} from "../common/utils";
import ProgressBar from "../components/ProgressBar";
import { getPeertubeAccessToken } from "../util/getPeertubeToken";
import "./Field.css";

const getAsset = async (
  assetId,
  apiClient,
  uniqueApplicationKey,
  contentfulSDK
) => {
  try {
    const accessTokenData = await getPeertubeAccessToken(uniqueApplicationKey);
    const { token: accessToken } = accessTokenData;

    const getVideoOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const assetRes = await apiClient.get(
      `${PEERTUBE_ENDPOINTS.GET_VIDEO_DETAILS}/${assetId}`,
      getVideoOptions
    );
    const { data: assetData } = assetRes;
    return assetData;
  } catch (error) {
    return handleApiError(error, contentfulSDK);
  }
};

const Field = () => {
  const sdk = useSDK();

  const UNIQUE_APPLICATION_KEY = generateAppUniqueKey(sdk.ids);
  const [parameters, setParameters] = useState(null);

  const [progress, setProgress] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [apiClient, setApiClient] = useState(null);
  const [fieldValue, setFieldValue] = useState(null);
  const [selectedPrivacy, setSelectedPrivacy] = useState(1);
  const handlePrivacyOnChange = (event) => setSelectedPrivacy(Number(event.target.value));
  const [uploadVideoSubmitted, setUploadVideoSubmitted] = useState(false);

  const uploadVideo = async (e) => {
    setUploadVideoSubmitted(true);
    const title = get(e, "target.upload-title.value");
    const description = get(e, "target.upload-description.value", "");
    const video = get(e, "target.upload-video.files[0]");

    const peertubeChannelId = get(parameters, "peertubeChannelId");

    if (!title || !description || !video) {
      sdk.notifier.error("Missing required fields");
      return false;
    }

    const accessTokenData = await getPeertubeAccessToken(
      UNIQUE_APPLICATION_KEY
    );
    const { token: accessToken } = accessTokenData;

    let assetId;
    try {
      const uploadVideoData = {
        name: title,
        channelId: peertubeChannelId,
        description,
        waitTranscoding: true,
        privacy: selectedPrivacy,
      };

      const uploadVideoFormData = new FormData();
      Object.entries(uploadVideoData).forEach(([key, value]) =>
        uploadVideoFormData.append(key, value)
      );

      uploadVideoFormData.append("videofile", video, video.name);

      const uploadVideoOptions = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          let progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress.toFixed(0));
        },
      };

      const uploadVideoRes = await apiClient.post(
        PEERTUBE_ENDPOINTS.UPLOAD_VIDEO,
        uploadVideoFormData,
        uploadVideoOptions
      );
      const { data: uploadedVideoData } = uploadVideoRes;
      assetId = get(uploadedVideoData, "video.uuid");
      await sdk.field.setValue({
        assetId,
      });
    } catch (error) {
      handleApiError(error, sdk);
    } finally {
      if (assetId) {
        await pollForAssetDetails(assetId);
      }
      setUploadVideoSubmitted(false);
      setProgress(0);
    }
  };

  const resetField = async () => {
    await sdk.field.setValue(undefined);
    setProgress(0);
    setFieldValue(undefined);
    setError(null);
    setUploadVideoSubmitted(false);
    await sleep(1000);
  };

  const pollForAssetDetails = async (inputAssetId) => {
    const assetId = fieldValue?.assetId || inputAssetId;
    if (!assetId) {
      throw Error(
        "Something went wrong, because by this point we require an assetId."
      );
    }

    const assetRes = await getAsset(
      assetId,
      apiClient,
      UNIQUE_APPLICATION_KEY,
      sdk
    );

    if (assetRes && assetRes?.assetNotFound) {
      setError('Error: The video was not found.');
      return
    }

    if (!assetRes) {
      throw Error("Something went wrong, we were not able to get the asset.");
    }

    const videoUrl = get(assetRes, "streamingPlaylists[0].playlistUrl");

    // CHECKING FOR ASSET STATUS
    const assetStateId = get(assetRes, "state.id");

    const assetDataValue = {
      version: 1,
      name: assetRes?.name,
      assetId: assetId,
      ready: assetRes.state.id === PEERTUBE_ASSET_STATE.PUBLISHED,
      // ratio: asset.aspect_ratio || undefined,
      // max_stored_resolution: asset.max_stored_resolution || undefined,
      // max_stored_frame_rate: asset.max_stored_frame_rate || undefined,
      duration: assetRes.duration || undefined,
      // audioOnly: audioOnly,
      created_at: assetRes.createdAt || undefined,
      videoUrl,
      // captions: captions && captions.length > 0 ? captions : undefined,
    }

    const assetDetails = {
      ...assetDataValue,
      streamingPlaylists: assetRes.streamingPlaylists,
      dataValue: assetDataValue
    };

    await sdk.field.setValue({ ...assetDataValue });
    setFieldValue(assetDetails);

    if (assetStateId !== PEERTUBE_ASSET_STATE.PUBLISHED) {
      await sleep(500);
      await pollForAssetDetails(assetId);
    }
  };

  const requestRemoveAsset = async () => {
    const result = await sdk.dialogs.openConfirm({
      title: "Are you sure you want to remove this asset?",
      message:
        "This will remove the asset in Contentful, but remain in Cappital Video Platform.",
      intent: "negative",
      confirmLabel: "Yes, remove",
      cancelLabel: "Cancel",
    });

    if (!result) {
      setIsDeleting(false);
      return;
    }

    setIsDeleting(true);
    await resetField();
    setIsDeleting(false);
  };

  const requestDeleteAsset = async () => {
    if (!fieldValue || !fieldValue.assetId) {
      throw Error(
        "Something went wrong, we cannot delete an asset without an assetId."
      );
    }

    const { assetId } = fieldValue;

    const result = await sdk.dialogs.openConfirm({
      title: "Are you sure you want to delete this asset?",
      message: "This will delete the asset in both Cappital Video Platform and Contentful.",
      intent: "negative",
      confirmLabel: "Yes, Delete",
      cancelLabel: "Cancel",
    });

    if (!result) {
      setIsDeleting(false);
      return;
    }

    setIsDeleting(true);
    await sleep(500);

    const accessTokenData = await getPeertubeAccessToken(UNIQUE_APPLICATION_KEY);
    const { token: accessToken } = accessTokenData;

    const getVideoOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const deleteRes = await apiClient.delete(
      `${PEERTUBE_ENDPOINTS.GET_VIDEO_DETAILS}/${assetId}`,
      getVideoOptions
    );

    if (deleteRes.status === 204) {
      sdk.notifier.success('Asset deleted successfully!')
    }

    await resetField();
    setIsDeleting(false);
  };

  const resync = async () => {
    try {
      await pollForAssetDetails()
      sdk.notifier.success('Updated: Data was synced with Cappital Video Platform.');
      await reloadPlayer();
    } catch (error) {

    }
  };

  const reloadPlayer = async () => {
    if (!fieldValue || !fieldValue?.dataValue) return;
    // Toggle for Player to reload manifest and see/remove captions.
    setIsRefreshing(true);
    // A slight delay was required for captions to show.
    await sleep(300).then(() => {
      setIsRefreshing(false);
    });
  };

  const uploadCaption = async () => { };

  const autofillCaptionCode = async () => { };

  const updateLangCode = async () => {
    try {
    } catch (error) { }
  };

  useEffect(() => {
    (async () => {
      // Get current parameters of the app
      // If the app is not installed yet, `parameters` will be `null`.
      const { installation: currentParameters } = sdk.parameters;
      if (currentParameters) {
        setParameters(currentParameters);
        const peertubeUrl = get(currentParameters, "peertubeUrl");
        const apiClient = new AxiosClient(peertubeUrl);
        setApiClient(apiClient);
      }

      const field = sdk.field.getValue();
      if (field) {
        setFieldValue(field);
      }
      await sleep(1000);
    })();
  }, [sdk]);

  useEffect(() => {
    (async () => {
      if (fieldValue) {
        await pollForAssetDetails(fieldValue?.assetId)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  if (isDeleting) {
    return (
      <Note variant="neutral" className="center" data-testid="deletemessage">
        <Spinner size="medium" /> Deleting this asset.
      </Note>
    );
  }

  if (error) {
    return (
      <div>
        <Note variant="negative" className="center" data-testid="terminalerror">
          <Flex justifyContent="space-between" alignItems="center">
            {error}
            <Button
              variant="negative"
              size="small"
              onClick={resetField}
              className="reset-field-button">
              Reset this field
            </Button>
          </Flex>
        </Note>
      </div>
    )
  }

  if (fieldValue && !fieldValue?.assetId) {
    return (
      <div>
        <Note variant="negative" className="center" data-testid="terminalerror">
          <Flex justifyContent="space-between" alignItems="center">
            Something went wrong Asset ID not found
            <Button
              variant="negative"
              size="small"
              onClick={resetField}
              className="reset-field-button">
              Reset this field
            </Button>
          </Flex>
        </Note>
      </div>
    )
  }

  if (fieldValue) {
    if (fieldValue?.assetId && !fieldValue?.ready) {
      return (
        <section
          data-testid="waitingtoplay"
          className="uploader_area center aspectratio"
        >
          <Flex flexDirection="column" gap="spacingS" justifyContent="space-between" alignItems="center">
            <Spinner size="small" /> Waiting for asset to be playable
            <Button
              variant="negative"
              size="small"
              onClick={resetField}
              className="reset-field-button"
            >
              Reset this field
            </Button>
          </Flex>
        </section>
      );
    }

    return (
      <div>
        <section className="player">
          {!isRefreshing ? (
            <Flex flexDirection="column" gap="spacingS" justifyContent="space-between">
              <h1>{fieldValue?.name}</h1>
              <ReactPlayer
                url={fieldValue?.videoUrl}
                controls
                width="100%"
                height="100%"
              />
            </Flex>
          ) : (
            <Box>
              <Spinner size="small" /> Refreshing Player
            </Box>
          )}
        </section>

        <section data-testid="menu_header">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="spacingM"
          >
            <Flex alignItems="center">
              <Flex marginRight="spacingM">
                <CopyButton
                  value={fieldValue?.assetId}
                  tooltipText="Asset ID"
                />
              </Flex>
            </Flex>
            <Flex>
              <Flex marginRight="spacingM">
                <Button
                  variant="secondary"
                  onClick={requestRemoveAsset}
                  id="remove"
                >
                  Remove
                </Button>
              </Flex>
              <Flex>
                <Button
                  variant="negative"
                  onClick={requestDeleteAsset}
                  data-testid="delete-button"
                >
                  Delete
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </section>

        <Tabs defaultTab="debug">
          <Tabs.List variant="horizontal-divider">
            {/* <Tabs.Tab panelId="captions">Captions</Tabs.Tab> */}
            <Tabs.Tab panelId="debug">Data</Tabs.Tab>
          </Tabs.List>

          {/* <Tabs.Panel id="captions">
            <Box marginTop="spacingL" marginBottom="spacingL">
              {fieldValue?.dataValue?.captions && fieldValue?.dataValue?.captions.length > 0 ? (
                <Note variant="neutral">NEED Captions LISTING</Note>
              ) : (
                <Note variant="neutral">No Captions</Note>
              )}
            </Box>

            <Form onSubmit={uploadCaption}>
              <Heading as="h3">Add</Heading>
              <FormControl isRequired>
                <FormControl.Label>Caption or Subtitle File URL</FormControl.Label>
                <TextInput type="url" name="url" />
              </FormControl>
              <FormControl isRequired>
                <FormControl.Label>Language Name</FormControl.Label>
                <TextInput
                  type="text"
                  name="name"
                  list="countrycodes"
                  onChange={autofillCaptionCode}
                />
              </FormControl>
              <FormControl isRequired>
                <FormControl.Label>Language Code</FormControl.Label>
                <TextInput
                  type="text"
                  name="languagecode"
                  value={fieldValue.captionname}
                  onChange={updateLangCode}
                />
                <Note variant="neutral">TODO: Need Language Code module here</Note>
              </FormControl>
              <FormControl>
                <Checkbox name="closedcaptions"> Closed Captions</Checkbox>
              </FormControl>
              <Button variant="secondary" type="submit">
                Submit
              </Button>
            </Form>
          </Tabs.Panel> */}

          <Tabs.Panel id="debug">
            <Box marginTop="spacingS">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                marginBottom="spacingM"
              >
                <Flex marginRight="spacingM">
                  <Button id="resync" variant="secondary" onClick={resync}>
                    Resync
                  </Button>
                </Flex>
              </Flex>
            </Box>

            {fieldValue?.streamingPlaylists?.length > 1 ? (
              <Note variant="warning">
                This Asset ID has multiple playlistUrl. Only the
                first will be used in Contentful.
              </Note>
            ) : (
              ""
            )}

            <pre>
              <Box as="code" display="inline" marginRight="spacingL">
                {JSON.stringify(fieldValue?.dataValue, null, 2)}
              </Box>
            </pre>
          </Tabs.Panel>
        </Tabs>
      </div>
    );
  }

  return (
    <section>
      <Box marginBottom="spacingM">
        <div className="uploader_area">
          <div style={{ padding: "20px" }}>
            <Form onSubmit={uploadVideo}>
              <FormControl>
                <FormControl.Label isRequired>Title</FormControl.Label>
                <TextInput name="upload-title" />
              </FormControl>
              <FormControl>
                <FormControl.Label isRequired>Description</FormControl.Label>
                <TextInput name="upload-description" />
              </FormControl>
              <FormControl>
                <FormControl.Label isRequired>Video</FormControl.Label>
                <TextInput name="upload-video" type="file" accept=".mp4" />
              </FormControl>
              <FormControl>
                <FormControl.Label isRequired>Privacy</FormControl.Label>
                <Select
                  id=""
                  name="optionSelect-controlled"
                  value={selectedPrivacy}
                  onChange={handlePrivacyOnChange}
                >
                  <Select.Option value="1">Public</Select.Option>
                  <Select.Option value="2">Unlisted</Select.Option>
                  <Select.Option value="3">Private</Select.Option>
                  <Select.Option value="4">Internal</Select.Option>
                </Select>
              </FormControl>
              {progress > 0 && <ProgressBar progress={progress} />}
              <Button
                variant="primary"
                type="submit"
                isDisabled={uploadVideoSubmitted}
              >
                {uploadVideoSubmitted ? "Submitted" : "Submit"}
              </Button>
            </Form>
          </div>
        </div>
      </Box>
    </section>
  );
};

export default Field;
